import React from 'react';

import './endInfo.css';

class EndInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
        console.log(this.props)
    }

    render() {
        return (
            <div className="ed-mb">
                 <div className="ed-mb-info"><a className="ed-mb-info" rel="noreferrer" target="_blank" href="https://beian.miit.gov.cn/">粤ICP备19090915号</a></div>
                <div className="ed-mb-info">Copyright©2021  版权所有 </div>
            </div>
          );
    }  
}

export default EndInfo;