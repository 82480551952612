import React from 'react';
import { Spin, message} from 'antd';
import { HttpPost } from '../common/httpRequest';

import './pay.css';

class Pay extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading:true,
            is_send:false,
            qrcode:null,
        }

        this.timer = null;
        console.log(this.props)
    }

    componentDidMount() {
        if (this.props.location && this.props.location.state && this.props.location.state.order_sn) {
            this.getPayInfo(this.props.location.state.order_sn);
            this.Carousedo(this.props.location.state.order_sn);
        }
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    // 开始轮询
    Carousedo = (order_sn) => {
        this.timer = setInterval(() => this.getPayStatus(order_sn), 2000);
    }

    getPayStatus = async (order_sn) => {
        let postData = {
            order_sn: order_sn,
            timestamp: (Date.parse(new Date()) / 1000)
        }

        let retData = await HttpPost('/index.php?r=order/pay-status',postData);
        console.log(retData);
        if (retData && retData.code) {
            if (parseInt(retData.code) === 60210) {
                clearInterval(this.timer);
                this.props.history.push("/follow", this.props.location.state);
            }else {
                console.log(retData);
            }
        }
    }

    getPayInfo = async (order_sn) => {
        if (this.state.is_send) {
            return false;
        }

        this.setState({is_send:true});
        let postData = {
            order_sn: order_sn,
            timestamp: (Date.parse(new Date()) / 1000)
        }
        console.log('sendData', postData);
        let retData = await HttpPost('/index.php?r=order/pay-qrcode',postData);
        console.log(retData);

        if (retData && retData.code) {
            if (parseInt(retData.code) === 60201) {
                this.setState({qrcode:retData.data.baseImage, loading:false})
            } else {
                message.error(retData.message ? retData.message : '获取二维码图片失败！');
            }
        }else {
            message.error('获取二维码图片失败');
        }

        this.setState({loading:false, is_send:false});
    }

    render() {
        return (
            <div className="pc-pay">
                <div className="pc-p-d">
                    <div>
                        <div className="pc-p-dt">
                            <div className="pc-p-dt-n">{this.props.location.state.username}</div>
                            <div className="pc-p-dt-s">
                                <div className="pc-p-dt-ss">签名款式</div>
                                <div className="pc-p-dt-st">{this.props.location.state.style_str}</div>
                            </div>
                            <div className="pc-p-dt-p">
                                <div className="pc-p-dt-pl">价格:￥</div>
                                <div className="pc-p-dt-pr">{this.props.location.state.money}</div>
                            </div>
                        </div>
                        <div  className="pc-p-de">
                            <div className="pc-p-eq">
                                <Spin tip="Loading..." spinning={this.state.loading}>
                                    <div><img src={this.state.qrcode ? 'data:image/png;base64,' + this.state.qrcode : ''} className="pc-p-i-img" alt="获取支付二维码图片失败"></img></div>
                                </Spin>
                            </div>
                            <div className="pc-p-et">微信扫一扫,完成支付</div>
                        </div>
                    </div>
                </div>
            </div>
          );
    }
}

export default Pay;