import React from 'react';
import { Button, message } from 'antd';
import { HttpPost } from '../common/httpRequest';

import './autograph.css';

class Autograph extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            is_send: false,
            username:'',
            arrStyle:[]
        }
        console.log(this.props)
    }
    inputChange = (v) => {
        console.log(v.target.value);
        this.setState({username:v.target.value});
    }

    setStyle = (value) => {
        let arrStyle = this.state.arrStyle;

        let index = arrStyle.indexOf(value);
        console.log(index, arrStyle);
        if (index === -1) {
            arrStyle.push(value);
        }else {
            arrStyle.splice(index, 1);
        }
        console.log(arrStyle);

        this.setState({arrStyle:arrStyle});
    }

    //提交信息
    sendData = async () => {
        if (this.state.is_send) {
            return false;
        }

        if (this.state.username === '') {
            message.error('请输入签名的名字');
            return false;
        }

        if (this.state.arrStyle.length === 0) {
            message.error('请选择签名款式');
            return false;
        }

        this.setState({loading: true, is_send:true});
        let postData = {
            username: this.state.username,
            arrStyle: this.state.arrStyle,
            timestamp: (Date.parse(new Date()) / 1000)
        }
        console.log('sendData', postData);
        let retData = await HttpPost('/index.php?r=order/create-order',postData);
        console.log(retData);

        if (retData && retData.code) {
            if (parseInt(retData.code) === 60105) {
                console.log(retData);
                let usa = window.navigator.userAgent.toLowerCase();
                if (usa.match(/MicroMessenger/i) == 'micromessenger') {
                    let jupm_url = 'https://sign.chshstyle.com/pay?' + retData.data.query_str;
                    window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + retData.data.appid + '&redirect_uri=' + encodeURIComponent(jupm_url) + '&response_type=code&scope=snsapi_base&state=wxpay#wechat_redirect';
                }else {
                    this.props.history.push("/pay", retData.data);
                }
            } else {
                message.error(retData.message ? retData.message : '获取支付信息失败！');
            }
        }

        this.setState({loading:false, is_send:false});
    }

    render() {
        let arrStyle = this.state.arrStyle;

        return (
            <div className="au-mb">
                <div className="au-mb-username">
                   <input className="au-mb-username-in" name="username" type="text" maxlength="6" placeholder="请输入签名的名字" onChange={(value) => this.inputChange(value)} />
                </div>
                <div className="au-mb-style">
                    <fieldset className="au-mb-style-f">
                        <legend align="left" className="au-mb-style-fl">签名款式</legend>
                        <div className="au-mb-style-fd">
                            <div className="au-mb-style-fdd">
                                <button className={arrStyle.indexOf(1) === -1 ? "au-mb-style-fddb" : "au-mb-style-fddb-c"} onClick={() => this.setStyle(1)}>艺术签</button>
                            </div>
                            <div className="au-mb-style-fdd">
                                <button className={arrStyle.indexOf(2) === -1 ? "au-mb-style-fddb" : "au-mb-style-fddb-c"} onClick={() => this.setStyle(2)}>连笔签</button>
                            </div>
                            <div className="au-mb-style-fdd">
                                <button className={arrStyle.indexOf(3) === -1 ? "au-mb-style-fddb" : "au-mb-style-fddb-c"} onClick={() => this.setStyle(3)}>商务签</button>
                            </div>
                            <div className="au-mb-style-fdd">
                                <button className={arrStyle.indexOf(4) === -1 ? "au-mb-style-fddb" : "au-mb-style-fddb-c"} onClick={() => this.setStyle(4)}>个性签</button>
                            </div>
                            <div className="au-mb-style-fdd">
                                <button className={arrStyle.indexOf(5) === -1 ? "au-mb-style-fddb" : "au-mb-style-fddb-c"} onClick={() => this.setStyle(5)}>可爱签</button>
                            </div>
                            <div className="au-mb-style-fdd">
                                <button className={arrStyle.indexOf(6) === -1 ? "au-mb-style-fddb" : "au-mb-style-fddb-c"} onClick={() => this.setStyle(6)}>潇洒签</button>
                            </div>
                            <div className="au-mb-style-fdd">
                                <button className={arrStyle.indexOf(7) === -1 ? "au-mb-style-fddb" : "au-mb-style-fddb-c"} onClick={() => this.setStyle(7)}>楷书签</button>
                            </div>
                            <div className="au-mb-style-fdd">
                                <button className={arrStyle.indexOf(8) === -1 ? "au-mb-style-fddb" : "au-mb-style-fddb-c"} onClick={() => this.setStyle(8)}>行书签</button>
                            </div>
                            <div className="au-mb-style-fdd">
                                <button className={arrStyle.indexOf(9) === -1 ? "au-mb-style-fddb" : "au-mb-style-fddb-c"} onClick={() => this.setStyle(9)}>抄书签</button>
                            </div>
                        </div>
                        
                    </fieldset>
                </div>
                <div className="au-mb-but">
                    <Button style={{backgroundColor: '#c20523',height:40,border:0,width:250}} className="au-but-b" type="primary" shape="round" loading={this.state.loading} onClick={() => this.sendData()}>给我设计</Button>
                </div>
            </div>
        );
    }  
}

export default Autograph;