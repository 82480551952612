import React from 'react';
import { Carousel } from 'antd';
import Autograph from './autograph';
import CarouselList from './carouselList';
import Qrcode from './qrcode';
import EndInfo from './endInfo';
import { HttpPost } from '../common/httpRequest';

import img_1 from '../../images/6.jpg';
import img_2 from '../../images/7.jpg';
import img_3 from '../../images/11.jpg';
import img_4 from '../../images/9.jpg';
import img_5 from '../../images/10.jpg';

import './pcHome.css';

class PCHome extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            is_send:false,
            total:'00000'
        }

        this.htimer = null;
        console.log(this.props);
    }

    componentDidMount() {
        this.htimer = setInterval(() => this.getUserTotal(), 5000);
    }

    componentWillUnmount() {
        clearInterval(this.htimer);
    }

    getUserTotal = async () => {
        if (this.state.is_send) {
            return ;
        }
        this.setState({is_send:true});
        
        let postData = {
            timestamp: (Date.parse(new Date()) / 1000)
        }

        let retData = await HttpPost('/index.php?r=statistics/get-user-total',postData);
        console.log(retData);
        if (retData && retData.code) {
            if (parseInt(retData.code) === 60015) {
                this.setState({total:retData.data.total})
            }else {
                console.log(retData);
            }
        }
        this.setState({is_send:false});
    }

    render() {
        return (
            <div id="app-pc" className="App-pc">
                <div className="pc-body">
                    <div className="pc-bo-a">
                        <div className="pc-header">
                            <div className="pc-header-left">百家艺术签名</div>
                            <div className="pc-header-middle"></div>
                            <div className="pc-header-right"></div>
                        </div>
                        <div className="pc-middle">
                            <div className="pc-mid-top">
                                <div className="pc-mid-top-left">
                                    <Carousel autoplay className="pc-mid-top-left" centerMode>
                                        <img src={img_1} alt="none" className="pc-mid-top-l-img"></img>
                                        <img src={img_2} alt="none" className="pc-mid-top-l-img"></img>
                                        <img src={img_3} alt="none" className="pc-mid-top-l-img"></img>
                                        <img src={img_4} alt="none" className="pc-mid-top-l-img"></img>
                                        <img src={img_5} alt="none" className="pc-mid-top-l-img"></img>
                                    </Carousel>
                                </div>
                                <div className="pc-mid-top-right">
                                    <Autograph {...this.props} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="pc-bo-b">
                        <div className="pc-m-middle">
                            <div className="pc-m-mid-t">
                                <div className="pc-m-mid-tt">{this.state.total}</div>
                            </div>
                            <div className="pc-mid-middle">
                                <div className="pc-mid-mid-left">
                                    <CarouselList />
                                </div>
                                <div className="pc-mid-mid-right">
                                    <Qrcode />
                                </div>
                            </div>
                            <div className="pc-end">
                                <EndInfo />
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
          );
    }
}

export default PCHome;