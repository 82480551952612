import React, { createRef } from 'react';
import { HttpPost } from '../common/httpRequest';

import './carouselList.css';

class CarouselList extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
        speed:30,   // 滚动速度，值越小，滚动越快
        isScrolle:true
      }

      this.list = [
        "用户161****1622,7分钟前购买了行书签\\艺术签...",
        "用户158****1322,30分钟前购买了行书签\\连笔签...",
        "用户159****1637,41分钟前购买了抄书签\\艺术签...",
        "用户171****6134,20分钟前购买了艺术签\\连笔签...",
        "用户138****3354,6分钟前购买了连笔签\\艺术签...",
        "用户153****9204,18分钟前购买了行书签\\艺术签...",
        "用户172****3942,38分钟前购买了艺术签\\连笔签...",
        "用户154****3842,22分钟前购买了商务签\\连笔签...",
        "用户153****8566,33分钟前购买了可爱签\\艺术签...",
        "用户164****1221,48分钟前购买了可爱签\\连笔签...",
        "用户136****2759,19分钟前购买了行书签\\艺术签..."
      ];
      this.timer = null;
      this.childDom1 = createRef();
      this.childDom2= createRef();
      this.warper = createRef();
      console.log(this.props)

  }
  

  componentDidMount(){
    this.getUserInfo();
    this.Carousedo();
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  // 开始滚动
  Carousedo = () => {
      // 多拷贝一层，让它无缝滚动
      this.childDom2.current.innerHTML = this.childDom1.current.innerHTML;
      this.timer = setInterval(() => this.warper.current.scrollTop >= this.childDom1.current.scrollHeight ? (this.warper.current.scrollTop = 0) : this.warper.current.scrollTop++, this.state.speed);
  }

  //获取用户交易信息记录
  getUserInfo = async () => {
    if (this.state.is_send) {
        return ;
    }
    this.setState({is_send:true});
    
    let postData = {
        timestamp: (Date.parse(new Date()) / 1000)
    }

    let retData = await HttpPost('/index.php?r=statistics/get-user-list',postData);
    console.log(retData);
    if (retData && retData.code) {
        if (parseInt(retData.code) === 60016) {
          let list = this.list;
          list = list.concat(retData.data.list);
          this.list = list;
        }else {
            console.log(retData);
        }
    }
    this.setState({is_send:false});
  }

  render() {
    return (
      <div className="sh-mb">
          <div className="sh-mb-parent" ref={this.warper}>
            <div className="sh-mb-child" ref={this.childDom1}>
                {this.list.map((item, index) => (
                    <div
                        key={index}
                        className="sh-mb-c-d"
                        onMouseOver={() => this.hoverHandler(false)}
                        onMouseLeave={() => this.hoverHandler(true)}
                    >
                        {item}
                    </div>
                ))}
            </div>
            <div className="sh-mb-child" ref={this.childDom2}></div>
        </div>
      </div>
    );
  }  
}

export default CarouselList;