import React from 'react';
import { message, Modal, Input, Button} from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import { HttpPost } from '../common/httpRequest';
import qrcode from '../../images/public_qrcode.jpg';

import './follow.css';

class Follow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            query:null,
            order_sn:'',
            cellphone:'',
            showmodel:true
        }
        console.log(this.props);
        console.log(this.props.location.search);
    }

    componentDidMount() {
        if (this.props.location.search && this.props.location.search !== '') {
            this.parseQuery();
        }
    }

    handleCancel = () => {
        this.setState({showmodel:false});
        
    }

    onChangeCellphone = (v) => {
        this.setState({cellphone:v.target.value});
    }

    parseQuery = () => {
        let order_sn = '';
        let queryObj = {};

        window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
            // 如果是带#进行路由的PWA项目，有可能会存在下面if这个场景
            if (value.indexOf('#') > -1) {
                value = value.split('#')[0];
            }
            queryObj[key] = value;
        });

        order_sn = queryObj.order ? queryObj.order : '';

        if (order_sn.length < 2) {
            order_sn = this.props.location && this.props.location.state ? this.props.location.state.order_sn : '';
        }

        this.setState({query:queryObj, order_sn: order_sn});
    }

    handleCopyWechat = () => {
        const spanText = document.getElementById('follow_wechat').innerText;
        const oInput = document.createElement('input');
        oInput.value = spanText;
        document.body.appendChild(oInput);
        oInput.select(); // 选择对象
        document.execCommand('Copy'); // 执行浏览器复制命令
        oInput.className = 'oInput';
        oInput.style.display = 'none';
        document.body.removeChild(oInput);
        message.success('微信公众号复制成功')
    };

    handleCopyOrder = () => {
        const spanText = document.getElementById('order_sn').innerText;
        const oInput = document.createElement('input');
        oInput.value = spanText;
        document.body.appendChild(oInput);
        oInput.select(); // 选择对象
        document.execCommand('Copy'); // 执行浏览器复制命令
        oInput.className = 'oInput';
        oInput.style.display = 'none';
        document.body.removeChild(oInput);
        message.success('订单号复制成功')
    };

    bindCellphone = async () => {
        let regexPhone = /^1[345789]{1}\d{9}$/;

        if (this.state.cellphone === '') {
            message.error('绑定手机号码不能为空');
            return false;
        }

        
        if (!regexPhone.test(this.state.cellphone)) {
            message.error('手机号码格式错误');
            return false;
        }

        if (this.state.order_sn.length < 2) {
            message.error('订单号错误');
            return false;
        }

        let postData = {
            cellphone:this.state.cellphone,
            order_sn: this.state.order_sn,
            timestamp: (Date.parse(new Date()) / 1000)
        }

        let retData = await HttpPost('/index.php?r=order/bind-cellphone',postData);
        console.log(retData);
        if (retData && retData.code) {
            if (parseInt(retData.code) === 60014) {
                message.success(retData.message);
                this.setState({showmodel:false})
            }else {
                message.error(retData.message);
            }
        }else {
            message.error('绑定失败');
        }
    }

    render() {
        return (
            <div className="mb-follow">
                <div className="mb-fo-d">
                    <div className="mb-fo-dt">
                        <div className="mb-fo-dt-n">查看签名设计结果</div>
                        <div className="mb-fo-dt-s">
                            <div className="mb-fo-dt-ss">关注微信公众号：</div>
                            <div id="follow_wechat" className="mb-fo-dt-st">art_signature</div>
                            <button className="mb-fo-dt-sb" onClick={() => this.handleCopyWechat()}>复制</button>
                        </div>
                        <div className="mb-fo-dt-o">
                            <div className="mb-fo-dt-os">订单号：</div>
                            <div id="order_sn" className="mb-fo-dt-ot">{this.props.location && this.props.location.state ? this.props.location.state.order_sn : 'NO20210809121547146'}</div>
                            <Button type="primary" size="small" shape="round" style={{marginTop:3, marginLeft:5, height:18, fontSize:10,backgroundColor:'red',border:0}} onClick={() => this.handleCopyOrder()}>复制</Button>
                        </div>
                        <div className="mb-fo-dt-b">
                            <button className="mb-fo-dt-bt">快速关注微信公众号</button>
                        </div>
                    </div>
                    <div className="mb-fo-dq">
                        <div className="mb-fo-dt-qr">
                            <div><img src={qrcode} className="mb-fo-dt-qri" alt="public_qrcode"></img></div>
                        </div>
                        <div className="mb-fo-dt-qrt">微信扫一扫,关注公众号</div>
                        <div className="mb-fo-dt-qrt">查看签名设计进度</div>
                    </div>
                </div>
                <Modal
                    closable={false}
                    footer={null}
                    title={null}
                    width={320}
                    visible={this.state.showmodel}
                >
                    <div className="mak-d">
                        <div className="mak-d-t">
                            <div className="mak-d-ti"><CheckCircleFilled style={{color:"#07c160", fontSize:30}}/></div>
                            <div>支付成功</div>
                        </div>
                        <div className="mak-d-c">
                            <Input placeholder="请绑定你的手机号码" style={{width:220,height:50, fontSize:18}} onChange={(v) => this.onChangeCellphone(v)}/>
                        </div>
                        <div className="mak-d-a">
                           警告：手机号码是你查看订单的唯一永久凭证，请正确填写，以便客服联系和订单查询
                        </div>
                        <div className="mak-d-b">
                            <Button  size="large" shape="round" style={{width:80}}  onClick={this.handleCancel}>取消</Button>
                            <Button type="primary" size="large" shape="round" style={{width:120, border:0, backgroundColor:'#07c160'}} onClick={this.bindCellphone}>提交</Button>
                        </div>
                    </div>
                </Modal>
            </div>
          );
    }
}

export default Follow;