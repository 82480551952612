import React from 'react';
import { BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import PcHome from './pcter/pcHome';
import Follow from './pcter/follow';
import Pay from './pcter/pay';

import 'antd/dist/antd.css';

class PCapp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
        console.log(this.props);
    }

    render(){
        return(
            <Router>
                <Switch>
                    <Route key='/' path="/" exact component={PcHome}/>
                    <Route key='/pay' path="/pay" exact component={Pay}/>
                    <Route key='/follow' path="/follow" exact component={Follow}/>
                </Switch>
            </Router>
        );
    }
}

export default PCapp;