import axios from 'axios';
import { message } from 'antd';

export async function HttpPost(url, jsonData, authorization) {
    let result = null;
    try {
        // let postUrl = '/api' + url;
        let postUrl = url;
        console.log('url: ', postUrl, ' ;postData: ', JSON.stringify(jsonData));
        let result = await axios({
            method: 'post',
            url: postUrl,
            headers: {
                'Authorization': authorization
            },
            timeout: 6000,
            data: jsonData
        });
        console.log('resultData: ', result)

        return result.data;
    } catch (e) {
        console.log(result)
        if (e.message.indexOf('timeout') !== -1) {
            message.error('服务器请求超时！');
        } else {
            message.error('服务器错误！');
        }

        console.log('axios error,', e.name + ':' + e.message);
        return false;
    }
}

export async function HttpGet(url, jsonData, authorization) {
    let result = null;

    try {
        // let getUrl = '/api' + url;
        let getUrl = url;
        console.log('url: ', getUrl, ' ;getData: ', JSON.stringify(jsonData));
        let result = await axios({
            method: 'get',
            url: getUrl,
            headers: {
                'Authorization': authorization
            },
            timeout: 6000,
            params: jsonData
        });
        console.log('resultData: ', result)

        return result.data;
    } catch (e) {
        console.log(result)
        if (e.message.indexOf('timeout') !== -1) {
            message.error('服务器请求超时！');
        } else {
            message.error('服务器错误！');
        }

        console.log('axios error,', e.name + ':' + e.message);
        return false;
    }
}