import React from 'react';
import qrcode from '../../images/public_qrcode.jpg';

import './qrcode.css';

class Qrcode extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
        console.log(this.props)
    }

    render() {
        return (
            <div className="qr-pc">
                <div className="qr-image">
                    <img src={qrcode} className="qr-image-img " alt="public_qrcode"></img>
                </div>
            </div>
          );
    }  
}

export default Qrcode;