import React from 'react';

import './endInfo.css';

class EndInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
        console.log(this.props)
    }

    render() {
        return (
            <div className="ed-pc">
                <div className="ed-pc-info">Copyright©2021  版权所有  <a className="ed-pc-info" rel="noreferrer" target="_blank" href="https://beian.miit.gov.cn/">粤ICP备19090915号</a></div>
            </div>
          );
    }  
}

export default EndInfo;