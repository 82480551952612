import React from 'react';
import { BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import MbHome from './mbter/mbHome';
import Follow from './mbter/follow';
import Pay from './mbter/pay';

class MBapp extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
        console.log(this.props)
    }

    render(){
        return(
            <Router>
                <Switch>
                    <Route key='/' path="/" exact component={MbHome}/>
                    <Route key='/pay' path="/pay" exact component={Pay}/>
                    <Route key='/follow' path="/follow" exact component={Follow}/>
                </Switch>
            </Router>
        );
    }
}

export default MBapp;