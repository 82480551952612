import React from 'react';
import { Button, message } from 'antd';
import { HttpPost } from '../common/httpRequest';

import './autograph.css';

class Autograph extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            is_send: false,
            username:'',
            arrStyle:[]
        }
        console.log(this.props)
    }

    inputChange = (v) => {
        console.log(v.target.value);
        this.setState({username:v.target.value});
    }

    setStyle = (value) => {
        let arrStyle = this.state.arrStyle;

        let index = arrStyle.indexOf(value);
        console.log(index, arrStyle);
        if (index === -1) {
            arrStyle.push(value);
        }else {
            arrStyle.splice(index, 1);
        }
        console.log(arrStyle);

        this.setState({arrStyle:arrStyle});
    }

    //提交信息
    sendData = async () => {
        if (this.state.is_send) {
            return false;
        }
        this.setState({loading:true, is_send:true});

        if (this.state.username === '') {
            message.error('请输入签名的名字');
            this.setState({loading:false, is_send:false});
            return false;
        }

        if (this.state.arrStyle.length === 0) {
            message.error('请选择签名款式');
            this.setState({loading:false, is_send:false});
            return false;
        }

        let postData = {
            username: this.state.username,
            arrStyle: this.state.arrStyle,
            timestamp: (Date.parse(new Date()) / 1000)
        }
        console.log('sendData', postData);
        let retData = await HttpPost('/index.php?r=order/create-order',postData);
        console.log(retData);

        if (retData && retData.code) {
            if (parseInt(retData.code) === 60105) {
                this.props.history.push("/pay", retData.data);
            } else {
                message.error(retData.message ? retData.message : '操作失败！');
            }
        }else {
            message.error('操作失败！');
        }

        this.setState({loading:true, is_send:false});
    }

    render() {
        let arrStyle = this.state.arrStyle;

        return (
            <div className="au-pc">
                <div className="au-username">
                   <input className="au-username-in" name="username" type="text" placeholder="请输入签名的名字" onChange={(value) => this.inputChange(value)}></input>
                </div>
                <div className="au-style">
                    <fieldset className="au-style-f">
                        <legend align="left" className="au-style-fl">签名款式</legend>
                        <div className="au-style-fd">
                            <div className="au-style-fdd">
                            {/* <Button className={arrStyle.indexOf(1) === -1 ? "au-style-fddb" : "au-style-fddb-c"} onClick={() => this.setStyle(1)}>艺术签</Button> */}

                                <Button type="primary" style={ arrStyle.indexOf(1) === -1 ? {height:40,fontSize:14, backgroundColor:'white',color:"black", borderColor:'#c20523'} : {height:40,fontSize:12,backgroundColor:'#c20523', border:0}}  className="au-style-fddb" onClick={() => this.setStyle(1)}>艺术签</Button>
                            </div>
                            <div className="au-style-fdd">
                                <Button type="primary" style={ arrStyle.indexOf(2) === -1 ? {height:40,fontSize:14, backgroundColor:'white',color:"black", borderColor:'#c20523'} : {height:40,fontSize:12,backgroundColor:'#c20523', border:0}}  className="au-style-fddb" onClick={() => this.setStyle(2)}>连笔签</Button>
                            </div>
                            <div className="au-style-fdd">
                                <Button type="primary" style={ arrStyle.indexOf(3) === -1 ? {height:40,fontSize:14, backgroundColor:'white',color:"black", borderColor:'#c20523'} : {height:40,fontSize:12,backgroundColor:'#c20523', border:0}}  className="au-style-fddb" onClick={() => this.setStyle(3)}>商务签</Button>
                            </div>
                            <div className="au-style-fdd">
                                <Button type="primary" style={ arrStyle.indexOf(4) === -1 ? {height:40,fontSize:14, backgroundColor:'white',color:"black", borderColor:'#c20523'} : {height:40,fontSize:12,backgroundColor:'#c20523', border:0}}  className="au-style-fddb" onClick={() => this.setStyle(4)}>个性签</Button>
                            </div>
                            <div className="au-style-fdd">
                                <Button type="primary" style={ arrStyle.indexOf(5) === -1 ? {height:40,fontSize:14, backgroundColor:'white',color:"black", borderColor:'#c20523'} : {height:40,fontSize:12,backgroundColor:'#c20523', border:0}}  className="au-style-fddb" onClick={() => this.setStyle(5)}>可爱签</Button>
                            </div>
                            <div className="au-style-fdd">
                                <Button type="primary" style={ arrStyle.indexOf(6) === -1 ? {height:40,fontSize:14, backgroundColor:'white',color:"black", borderColor:'#c20523'} : {height:40,fontSize:12,backgroundColor:'#c20523', border:0}}  className="au-style-fddb" onClick={() => this.setStyle(6)}>潇洒签</Button>
                            </div>
                            <div className="au-style-fdd">
                                <Button type="primary" style={ arrStyle.indexOf(7) === -1 ? {height:40,fontSize:14, backgroundColor:'white',color:"black", borderColor:'#c20523'} : {height:40,fontSize:12,backgroundColor:'#c20523', border:0}}  className="au-style-fddb" onClick={() => this.setStyle(7)}>楷书签</Button>
                            </div>
                            <div className="au-style-fdd">
                                <Button type="primary" style={ arrStyle.indexOf(8) === -1 ? {height:40,fontSize:14, backgroundColor:'white',color:"black", borderColor:'#c20523'} : {height:40,fontSize:12,backgroundColor:'#c20523', border:0}}  className="au-style-fddb"onClick={() => this.setStyle(8)}>行书签</Button>
                            </div>
                            <div className="au-style-fdd">
                                <Button type="primary" style={ arrStyle.indexOf(9) === -1 ? {height:40,fontSize:14, backgroundColor:'white',color:"black", borderColor:'#c20523'} : {height:40,fontSize:12,backgroundColor:'#c20523', border:0}}  className="au-style-fddb" onClick={() => this.setStyle(9)}>抄书签</Button>
                            </div>
                        </div>
                        
                    </fieldset>
                </div>
                <div className="au-but">
                    <Button style={{backgroundColor: '#c20523',height:40,border:1,width:250}} className="au-but-b" type="primary" shape="round" loading={this.state.loading} onClick={() => this.sendData()}>给我设计</Button>
                </div>
            </div>
          );
    }  
}

export default Autograph;